import breadcrumbComp from "../../common/breadcrumb-comp.vue";
import EncryptUtility from "../../utility/js/encrypt-utility";
import AddFavourite from "../../utility/js/addFav";
import BackToTop from "../../common/back-to-top.vue";
import reportsService from "./reports-service";
import exportLimit from "../../common/export-limit.vue";
import moment from "moment";
import Utility from "../../../shared/utility";
import html2pdf from "html2pdf.js";
export default {
  name: "Discrepancy Report",
  data() {
    return {
      userId: EncryptUtility.localStorageDecrypt("userID"),
      actionList: [],
      favouriteList: [],
      mainPage: "",
      subPage: "",
      backSlash: true,
      pageUrl: "",
      showAdd: false,
      refresh: true,
      selectedProject: "",
      projectData: [],
      disableRun: false,
      showData: false,
      totalRecords: 0,
      exportDisable: true,
      printDisabled: true,
      excelName: "",
      searchDiscrepancy: "",
      discrepancyItems: [],
      export50kData: false,
      exportDialog: false,
      maxStartDate: new Date().toISOString().split("T")[0],
      minEndDate: new Date().toISOString().split("T")[0],
      maxEndDate: new Date(new Date().getTime()).toISOString().split("T")[0],
      sDate: "",
      eDate: "",
      startDate: false,
      endDate: false,
      showStartDate: false,
      showEndDate: false,
      dateSelected: false,
      showDate: false,
      showGo: false,
      showStatus: false,
      selectedStatus: "",
      statusData: [],
      projectDisable: false,
      pdfName: "",
      totalLotDetails: [],
      disableStatus: false,
      apiObj: {
        user_id: 0,
        pass: 0,
        proj_key: 0,
        ldate1: "",
        ldate2: "",
        status: "",
      },
      headersOpenBTO: [
        { text: "Project", value: "Project", class: "primary customwhite--text" },
        { text: "Tag#", value: "Tag#", class: "primary customwhite--text" },
        { text: "Discrepancy Reason", value: "Discrepancy Reason", class: "primary customwhite--text" },
        { text: "Order Type", value: "Order Type", class: "primary customwhite--text" },
        { text: "Order#", value: "Order#", class: "primary customwhite--text" },
        { text: "RMA", value: "RMA", class: "primary customwhite--text" },
        { text: "Packslip#", value: "Packslip#", class: "primary customwhite--text" },
        { text: "Ref#", value: "Ref#", class: "primary customwhite--text" },
        { text: "Waybill", value: "Waybill", class: "primary customwhite--text" },
        { text: "Part#", value: "Part#", class: "primary customwhite--text" },
        { text: "Description", value: "Description", class: "primary customwhite--text" },
        { text: "Quantity", value: "Qty", class: "primary customwhite--text" },
        { text: "Unit Identifier", value: "Unit Identifier", class: "primary customwhite--text" },
        { text: "Company", value: "Company", class: "primary customwhite--text" },
        { text: "Name", value: "Name", class: "primary customwhite--text" },
        { text: "Address1", value: "Address1", class: "primary customwhite--text" },
        { text: "City/State/Zip", value: "City/State/Zip", class: "primary customwhite--text" },
        { text: "Phone", value: "Phone", class: "primary customwhite--text" },
        { text: "Logged By", value: "Logged By", class: "primary customwhite--text" },
        { text: "Status", value: "Status", class: "primary customwhite--text" },
        { text: "Resolution Order", value: "Resolution Order", class: "primary customwhite--text" },
        { text: "Line Color", value: "linecolor", class: "primary customwhite--text" },
      ],
      json_fields: {
        Project: "Project",
        "Tag#": "Tag#",
        "Discrepancy Reason": "Discrepancy Reason",
        "Order Type": "Order Type",
        "Order#": "Order#",
        RMA: "RMA",
        "Packslip#": "Packslip#",
        "Ref#": "Ref#",
        Waybill: "Waybill",
        "Part#": "Part#",
        Description: "Description",
        Quantity: "Qty",
        "Unit Identifier": "Unit Identifier",
        Company: "Company",
        Name: "Name",
        Address1: "Address1",
        "City/State/Zip": "City/State/Zip",
        Phone: "Phone",
        "Logged By": "Logged By",
        Status: "Status",
        "Resolution Order": "Resolution Order",
        "Line Color": "linecolor",
      },
    };
  },
  async created() {
    this.route = this.$route.path.replace("/", "");
    let data = await AddFavourite.getPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
    this.getProjectData();
  },
  computed: {
    computedDateFormattedMomentjs() {
      return this.sDate ? moment(this.sDate).format("MM/DD/YYYY") : moment(new Date().getTime() - 86400000).format("MM/DD/YYYY");
    },
    computedEndDateFormattedMomentjs() {
      return this.eDate ? moment(this.eDate).format("MM/DD/YYYY") : moment(new Date()).format("MM/DD/YYYY");
    },
  },
  methods: {
    //Reset Function
    resetFunction() {
      this.selectedProject = "";
      this.projectData = [];
      this.disableRun = false;
      this.showData = false;
      this.totalRecords = 0;
      this.exportDisable = true;
      this.printDisabled = true;
      this.excelName = "";
      this.searchDiscrepancy = "";
      this.discrepancyItems = [];
      this.export50kData = false;
      this.exportDialog = false;
      this.showStartDate = false;
      this.showEndDate = false;
      this.disableStatus = false;
      this.$nextTick(() => {
        this.showStartDate = true;
        this.sDate = null;
        this.showEndDate = true;
        this.eDate = null;
      });
      this.startDate = false;
      this.endDate = false;
      this.dateSelected = false;
      this.showDate = false;
      this.showGo = false;
      this.showStatus = false;
      this.selectedStatus = "";
      this.statusData = [];
      this.projectDisable = false;
      this.pdfName = "";
      this.totalLotDetails = [];
      this.apiObj = {
        user_id: 0,
        pass: 0,
        proj_key: 0,
        ldate1: "",
        ldate2: "",
        status: "",
      };
      this.getProjectData();
    },
    //Get Project data on page load
    async getProjectData() {
      let showMessage = false;
      this.apiObj.user_id = parseInt(this.userId);
      this.apiObj.pass = 0;
      let projData = await reportsService.discrepancyReport("post", this.apiObj, showMessage);
      if (projData.message !== "NA") {
        this.projectData = projData;
      }
    },
    //Set end Date
    setEndDate() {
      let minEndDate = new Date(this.sDate);
      this.minEndDate = new Date(minEndDate.getTime() + 86400000).toISOString().substr(0, 10);
      let maxSDate = new Date(this.maxStartDate);
      this.maxEndDate = new Date(maxSDate.getTime() + 0).toISOString().substr(0, 10);
    },
    //Project Selected
    projectSelected() {
      this.showDate = true;
      this.showGo = true;
      this.showStartDate = false;
      this.showEndDate = false;
      this.$nextTick(() => {
        this.showStartDate = true;
        this.sDate = null;
        this.showEndDate = true;
        this.eDate = null;
      });
      this.projectDisable = true;
    },
    //On Continue button click
    async onContinue() {
      let showMessage = false;
      this.apiObj.user_id = parseInt(this.userId);
      this.apiObj.pass = 1;
      this.apiObj.ldate1 = Utility.convertLocalToUTC(this.sDate);
      this.apiObj.ldate2 = Utility.convertLocalToUTC(this.eDate);
      let data = await reportsService.discrepancyReport("post", this.apiObj, showMessage);
      if (data.message !== "NA") {
        this.showGo = false;
        this.dateSelected = true;
        this.showStatus = true;
        this.statusData = data;
      }
    },
    //Run Report
    async runReport() {
      this.apiObj.user_id = parseInt(this.userId);
      this.apiObj.pass = 2;
      this.apiObj.ldate1 = Utility.convertLocalToUTC(this.sDate);
      this.apiObj.ldate2 = Utility.convertLocalToUTC(this.eDate);
      this.apiObj.proj_key = parseInt(this.selectedProject);
      this.apiObj.status = this.selectedStatus;
      let showMessage = false;
      let data = await reportsService.discrepancyReport("post", this.apiObj, showMessage);
      if (data.message !== "NA") {
        this.discrepancyItems = data;
        this.totalRecords = data.length;
        this.showData = true;
        this.exportDisable = false;
        this.printDisabled = false;
        this.disableStatus = true;
      } else {
        this.discrepancyItems = [];
        this.totalRecords = 0;
        this.showData = false;
        this.exportDisable = true;
        this.printDisabled = true;
        this.disableStatus = true;
      }
    },
    //Export Data using component
    async exportData() {
      this.export50kData = true;
      this.exportDialog = false;
    },
    //To export the Excel file
    async exportExcel() {
      if (this.totalRecords <= 15000) {
        this.apiObj.user_id = parseInt(this.userId);
        this.apiObj.pass = 2;
        this.apiObj.ldate1 = Utility.convertLocalToUTC(this.sDate);
        this.apiObj.ldate2 = Utility.convertLocalToUTC(this.eDate);
        this.apiObj.proj_key = parseInt(this.selectedProject);
        this.apiObj.status = this.selectedStatus;
        this.excelName =
          "Discrepancy_Report_" + new Date().toISOString().replace("T", "_").replace("Z", "").replace(".", "").replaceAll("-", "_") + ".xls";
        const response = await this.axios.post("/rt/discrepancy_report", this.apiObj);
        let responseData = JSON.parse(response.data.body.message);
        this.export50kData = false;
        return responseData;
      } else {
        this.exportDialog = true;
        this.finishDownload();
        return true;
      }
    },
    //Start the Loader when excel process starts
    startDownload() {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
    },
    //Wnd the Loader when the process is completed
    finishDownload() {
      let LoaderDialog = {
        visible: false,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
    },
    //Export pdf
    async printDetails() {
      this.startDownload();
      this.apiObj.user_id = parseInt(this.userId);
      this.apiObj.pass = 2;
      this.apiObj.ldate1 = Utility.convertLocalToUTC(this.sDate);
      this.apiObj.ldate2 = Utility.convertLocalToUTC(this.eDate);
      this.apiObj.proj_key = parseInt(this.selectedProject);
      this.apiObj.status = this.selectedStatus;
      const response = await this.axios.post("/rt/discrepancy_report", this.apiObj);
      let responseData = JSON.parse(response.data.body.message);
      responseData.forEach((item) => {
        delete item["boldbit"];
        delete item["enable_bit"];
        delete item["columncount"];
      });
      this.totalLotDetails = [];
      this.totalLotDetails = this.buildHtmlTable(responseData);
      this.pdfName =
        "Discrepancy_Report_" + new Date().toISOString().replace("T", "_").replace("Z", "").replace(".", "").replaceAll("-", "_") + ".pdf";
      html2pdf(this.totalLotDetails, {
        margin: 0.5,
        filename: this.pdfName,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { dpi: 190, letterRendering: true },
        jsPDF: { unit: "in", format: "a0", orientation: "landscape" },
      });
      this.finishDownload();
    },
    buildHtmlTable(response) {
      this.tblTag = document.createElement("table");
      this.trTag = document.createElement("tr");
      this.thTag = document.createElement("th");
      this.tdTag = document.createElement("td");
      let table = this.tblTag.cloneNode(false);
      table.setAttribute("cellpadding", "0");
      table.setAttribute("cellspacing", "0");
      table.style.border = "1px solid black";
      table.style.borderRight = "none";
      table.style.borderTop = "none";
      let columns = this.addAllColumnHeaders(response, table);
      columns = columns.filter((column) => column !== "tid");
      for (var i = 0, maxi = response.length; i < maxi; ++i) {
        var tr = this.trTag.cloneNode(false);
        for (var j = 0, maxj = columns.length; j < maxj; ++j) {
          var td = this.tdTag.cloneNode(false);
          td.style.border = "1px solid black";
          td.style.padding = "10px";
          td.style.borderLeft = "none";
          td.style.borderBottom = "none";
          var cellValue = response[i][columns[j]];
          td.appendChild(document.createTextNode(cellValue || ""));
          tr.appendChild(td);
        }
        table.appendChild(tr);
      }
      return table;
    },
    addAllColumnHeaders(response, table) {
      let columnSet = [];
      let tr = this.trTag.cloneNode(false);
      for (var i = 0, l = response.length; i < l; i++) {
        for (var key in response[i]) {
          if (columnSet.indexOf(key) === -1) {
            if (key != "tid") {
              columnSet.push(key);
              var th = this.thTag.cloneNode(false);
              th.style.border = "1px solid black";
              th.setAttribute("padding", "5px");
              th.style.borderLeft = "none";
              th.style.borderBottom = "none";
              th.style.backgroundColor = "#005ba8";
              th.style.color = "white";
              if (key == "Project") key = "Project";
              if (key == "Tag#") key = "Tag#";
              if (key == "Discrepancy Reason") key = "Discrepancy Reason";
              if (key == "Order Type") key = "Order Type";
              if (key == "Order#") key = "Order#";
              if (key == "RMA") key = "RMA";
              if (key == "Packslip#") key = "Packslip#";
              if (key == "Ref#") key = "Ref#";
              if (key == "Waybill") key = "Waybill";
              if (key == "Part#") key = "Part#";
              if (key == "Description") key = "Description";
              if (key == "Qty") key = "Quantity";
              if (key == "Unit Identifier") key = "Unit Identifier";
              if (key == "Company") key = "Company";
              if (key == "Name") key = "Name";
              if (key == "Address1") key = "Address1";
              if (key == "City/State/Zip") key = "City/State/Zip";
              if (key == "Phone") key = "Phone";
              if (key == "Logged By") key = "Logged By";
              if (key == "Status") key = "Status";
              if (key == "Resolution Order") key = "Resolution Order";
              if (key == "linecolor") key = "Line Color";
              th.appendChild(document.createTextNode(key));
              tr.appendChild(th);
            }
          }
        }
      }
      table.appendChild(tr);
      return columnSet;
    },
    //To add and remove From Fav
    addFav() {
      this.showAdd = !this.showAdd;
      AddFavourite.add_remove_Favourite(this.showAdd, this.userId, this.route);
    },
  },
  components: {
    breadcrumbComp,
    BackToTop,
    exportLimit,
  },
};
